<template>
    <div>
        <b-row no-gutters>
            <b-col lg="2" md="3" sm="12" xs="12">
                <sider-menu-items :items="menuSiderItems.items"/>
            </b-col>
            <b-col lg="10" md="9" sm="12" xs="12">
                <router-view></router-view>
            </b-col>
        </b-row>
    </div>
</template>

<script>

import SiderMenuItems from "@/components/general/elements/menus/SiderMenuItems";

export default {
    name: "AdminContent",
    components: {SiderMenuItems},
    props: {},
    data() {
        return {
            menuSiderItems: {
                // <-- user -->
                items: [
                    // <-- admin -->
                    {
                        type: 'text',
                        text: this.$t('admin.sider_menu.admin'),
                    },
                    {
                        type: 'item',
                        text: this.$t('admin.sider_menu.locale'),
                        path: '/admin/locales',
                        disabled: false,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('admin.sider_menu.project'),
                        path: '/admin/projects',
                        disabled: false,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('admin.sider_menu.tag_type'),
                        path: '/admin/tag_types',
                        disabled: true,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('admin.sider_menu.tag'),
                        path: '/admin/tags',
                        disabled: true,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('admin.sider_menu.access'),
                        path: '/admin/accesses',
                        disabled: false,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('admin.sider_menu.right'),
                        path: '/admin/rights',
                        disabled: false,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('admin.sider_menu.user'),
                        path: '/admin/users',
                        disabled: false,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('admin.sider_menu.organisation'),
                        path: '/admin/organisations',
                        disabled: false,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('admin.sider_menu.tenant'),
                        path: '/admin/tenants',
                        disabled: true,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('admin.sider_menu.delivery_premises'),
                        path: '/admin/delivery_premises',
                        disabled: true,
                        active: false,
                    },
                    {
                        type: 'item',
                        text: this.$t('admin.sider_menu.delivery'),
                        path: '/admin/delivery_delivery',
                        disabled: true,
                        active: false,
                    },
                ]
            },
        }
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
